import { useEffect, useRef, useContext, useState } from 'react';
import cn from 'classnames';
import { useHistory } from 'react-router-dom';
import isArray from 'lodash/isArray';

import createStyles from '@guestyci/foundation/createStyles';
import { Col, Row } from '@guestyci/foundation/Layout';
import t from '@guestyci/localize/t.macro/t.macro';
import useFeatureToggle from '@guestyci/feature-toggle-fe/useFeatureToggle';

import PropertyList from 'components/PropertyList';
import SearchBar from 'components/SearchBar';
import Map from 'components/MapContainer';
import NoResults from 'components/NoResults';
import NoPropertiesFound from 'components/NoPropertiesFound';
import useGetInfiniteProperties from 'hooks/useGetInfiniteProperties';
import useIsMobile from 'hooks/useIsMobile';
import useDio from 'hooks/useDio';
import useGetCurrencyRate from 'hooks/useGetCurrencyRate';
import usePureSearchValues from 'hooks/usePureSearchValues';
import useGetPathToNavigate from 'hooks/useGetPathToNavigate';
import { WebsiteSettingsContext } from 'context/WebsiteSettingsContext';
import { CurrencyContext } from 'context/CurrencyContext';
import { generateSearchParams } from 'utils';
import { SHOW_NO_PROPERTIES_PAGE } from 'constants/featureToggleNames';

const useStyles = createStyles(({ breakpoints: { create } }) => ({
  root: {
    background: '#fff',
    height: '100%',
  },
  noPropertiesFoundWrapper: {
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    [create('xs')]: {
      height: 'calc(100vh - 100px)',
    },
    [create('md')]: {
      height: 'auto',
    },
    [create('lg')]: {
      height: 'calc(100vh - 100px)',
    },
  },
  propertyListWrapper: {
    position: 'relative',
    maxWidth: ({ shouldShowMapOnSearch }) => (shouldShowMapOnSearch ? 1095 : '100%'),
    flexFlow: ({ shouldShowMapOnSearch }) => (shouldShowMapOnSearch ? 'column' : 'row wrap'),
    justifyContent: ({ shouldShowMapOnSearch }) => (shouldShowMapOnSearch ? 'flex-start' : 'center'),
    gap: 10,
    height: ({ shouldShowMapOnSearch }) => (shouldShowMapOnSearch ? '73vh' : '100%'),
    [create('xs')]: {
      width: '100%',
      paddingBottom: 10,
    },
    [create('md')]: {
      padding: '0px 20px',
    },
    [create('lg')]: {
      padding: '0px 20px 20px 0px',
      width: ({ shouldShowMapOnSearch }) => (shouldShowMapOnSearch ? '70%' : '100%'),
    },
  },
  content: {
    [create('xs')]: {
      padding: '0px 5px',
    },
    [create('md')]: {
      padding: '0px 20px 20px',
    },
  },
}));

const SearchPage = () => {
  const initialRender = useRef(true);
  const history = useHistory();
  const { path } = useGetPathToNavigate();
  const isMobile = useIsMobile();
  const [, isShowNoPropertiesPage] = useFeatureToggle(SHOW_NO_PROPERTIES_PAGE);
  const { dioTrack } = useDio();
  const [backwardCurrencyRate, setBackwardCurrencyRate] = useState({});
  const { displayOptions: { shouldShowMapOnSearch = false } = {} } = useContext(WebsiteSettingsContext);
  const { selectedCurrency, defaultCurrency } = useContext(CurrencyContext);
  const searchParamsFromUrl = usePureSearchValues();
  const [searchParams, setSearchParams] = useState(searchParamsFromUrl);
  const { minPrice, maxPrice } = searchParams;
  const searchParamsWithCurrency = {
    ...searchParams,
    minPrice: minPrice ? minPrice * (backwardCurrencyRate?.[selectedCurrency] || 1) : undefined,
    maxPrice: maxPrice ? maxPrice * (backwardCurrencyRate?.[selectedCurrency] || 1) : undefined,
  };

  const { data, fetchNextPage, hasNextPage, isSuccess, isFetching, isFetchingNextPage, isError } =
  useGetInfiniteProperties({
    search: {
      ...searchParamsWithCurrency,
    },
  });

  const isNoResult = !isFetching && isSuccess && isArray(data?.properties) && !data?.properties?.length;

  const { root, content, propertyListWrapper, noPropertiesFoundWrapper } = useStyles({ shouldShowMapOnSearch });

  const onSearchHandler = (params) => {
    const searchParamsString = generateSearchParams(params);
    setSearchParams(params);
    history.push({ pathname: path, search: searchParamsString });
  };

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else if (minPrice || maxPrice) {
      const searchParamsWithNoPrice = {
        ...searchParams,
        minPrice: undefined,
        maxPrice: undefined,
        currency: undefined,
      };
      onSearchHandler(searchParamsWithNoPrice);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCurrency]);

  useEffect(() => {
    if (data?.properties.length) {
      dioTrack('view_items_list', 'pageview', {
        listings: data?.properties.map((property, index) => ({
          listing_id: property._id,
          item_title: property?.title,
          listing_nickname: property?.nickname,
          index,
        })),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.properties]);

  const { fetchHandler: getCurrencyRate } = useGetCurrencyRate({
    onSuccessHandler: (rateData) => {
      const result = { ...backwardCurrencyRate, [selectedCurrency]: rateData.rate };
      setBackwardCurrencyRate(result);
    },
    from: selectedCurrency,
    to: defaultCurrency,
  });

  useEffect(() => {
    if (!selectedCurrency || selectedCurrency === defaultCurrency || backwardCurrencyRate[selectedCurrency]) return;
    getCurrencyRate();
  }, [selectedCurrency, backwardCurrencyRate, defaultCurrency, getCurrencyRate]);

  if (isShowNoPropertiesPage && isNoResult) {
    return (
      <Col className={root}>
        <div className={noPropertiesFoundWrapper}>
          {!isMobile && <SearchBar withAdditionalFilters totalListings={data?.total} onSubmit={onSearchHandler} />}
          <NoPropertiesFound handleClearSearch={onSearchHandler} />
        </div>
      </Col>
    );
  }

  return (
    <>
      <Col className={root}>
        {!isMobile && <SearchBar withAdditionalFilters totalListings={data?.total} onSubmit={onSearchHandler} />}
        <Row className={content}>
          {isError && <div>{t('Error')}</div>}
          <div
            data-property-list
            className={cn(propertyListWrapper, 'd-flex', { 'overflow-y-scroll': isSuccess && shouldShowMapOnSearch })}
          >
            {isMobile && <SearchBar withAdditionalFilters totalListings={data?.total} onSubmit={onSearchHandler} />}
            {isNoResult && <NoResults />}
            {isSuccess && (
              <PropertyList
                properties={data?.properties}
                isFetchingNextPage={isFetchingNextPage}
                hasNextPage={hasNextPage}
                onIntersect={fetchNextPage}
              />
            )}
          </div>
          {shouldShowMapOnSearch && isSuccess && (
            <div className="flex-1">
              <Map markers={data?.markers} />
            </div>
          )}
        </Row>
      </Col>
    </>
  );
};

export default SearchPage;
